import React, { useState } from "react";
import Style from "./Navbar.module.scss";
import Toggler from "./home/Toggler";
import { Link, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { info } from "../info/Info";

const links = [
  {
    name: "Home",
    to: "/",
    active: "home",
  },
  {
    name: "About Me",
    to: "/about",
    active: "about",
  },
  {
    name: "Portfolio",
    to: "/portfolio",
    active: "portfolio",
  },
];

export default function Navbar({ darkMode, handleClick }) {
  const location = useLocation();
  const [active, setActive] = useState(
    location.pathname === "/"
      ? "home"
      : location.pathname.slice(1, location.pathname.length)
  );

  return (
    <Box component={"nav"} width={"100%"}>
      <Box
        component={"ul"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={{ xs: "2rem", md: "8rem" }}
        textTransform={"lowercase"}
        fontSize={"1rem"}
      >
        {links.map((link, i) => (
          <Link key={i} to={link.to} onClick={() => setActive(link.active)}>
            <Box
              component={"li"}
              className={link.active === active && !link.type && Style.active}
              sx={{ borderImageSource: info.gradient }}
            >
              {!link.type && (
                <p
                  style={{
                    paddingBottom: "0.5rem",
                    textTransform: "uppercase",
                  }}
                >
                  {link.name}
                </p>
              )}
              {link.type && <h1>{link.name}</h1>}
            </Box>
          </Link>
        ))}
        <Toggler darkMode={darkMode} handleClick={handleClick} />
      </Box>
    </Box>
  );
}
